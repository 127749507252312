import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import styled from 'styled-components'
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
      "@type": "Question",
      "name": "What Is PHP?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "PHP is a popular scripting language used for website development."
      }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
      "@type": "Question",
      "name": "Why Do I Need A PHP Developer?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "More than 78.9% of all webs that use server-side programming are PHP-based. If you really want to get ahead of competitors, you need Codestaff PHP developers."
      }
    }
}

const productMeta = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": "PHP Developer",
    "image": "https://codestaff.io/freelance-php-developer.jpg",
    "description": "Freelance PHP Developers at Code Staff have all of the skill necessary to help your next project succeed. Hire a Remote PHP Developer and have them join your team.",
    "brand": "Code Staff",
    "offers": {
        "@type": "Offer",
        "url": "https://codestaff.io/php-developer",
        "priceCurrency": "USD",
        "price": "99",
        "priceValidUntil": "2020-05-27",
        "availability": "https://schema.org/OnlineOnly",
        "itemCondition": "https://schema.org/NewCondition"
    },
    "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "5",
        "bestRating": "5",
        "worstRating": "1",
        "ratingCount": "1",
        "reviewCount": "1"
    },
    "review": {
        "@type": "Review",
        "name": "David Alleva",
        "reviewBody": "",
        "reviewRating": {
            "@type": "Rating",
            "ratingValue": "5",
            "bestRating": "5",
            "worstRating": "1"
        },
        "datePublished": "2020-05-06",
        "author": { "@type": "Person", "name": "David Alleva" },
        "publisher": { "@type": "Organization", "name": "Freelancer Website" }
    }
}

const MainWrapper = styled.div`
margin: 0;
`

const PhpDeveloper = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
            <script type="application/ld+json">{JSON.stringify(productMeta)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> PHP Developers.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top PHP Developers. Top
                                    companies and start-ups choose Codestaff professional PHP
                                    Developers for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top PHP Developers now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE PHP DEVELOPERS' banner='/php-developer.png' bannerAlt='hp-developer banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default PhpDeveloper