import React from 'react'
import Layout from '../components/layout'
import PhpDeveloper from '../components/categories/php-developer'
import Seo from '../components/seo'

const PhpDeveloperPage = ({ location }) => {
  return (
    <Layout location={location}>
      <PhpDeveloper />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top PHP Developer Freelancers | Codestaff'
  const description =
    'Hire the best PHP Developer freelancers at Codestaff. Get the top 1% of PHP Developer professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default PhpDeveloperPage
